<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <div
        class="d-flex align-center justify-space-between px-8"
        style="height: calc(100vh - 300px)"
        fluid
      >
        <v-card flat :width="500" class="py-4" :loading="saving">
          <v-card-title class="text-h5 font-weight-semibold">
            {{ $t('auth.editProfile') }}
          </v-card-title>
          <v-card-text class="mt-4">
            <validation-provider
              rules="required|fullName"
              :name="$t('common.fullName')"
              v-slot="{ errors }"
            >
              <cz-form-field :label="$t('common.fullName')" required>
                <cz-input
                  v-model="currentUserCopy.fullName"
                  :error-messages="errors"
                />
              </cz-form-field>
            </validation-provider>
            <cz-form-field :label="$t('auth.profilePicture')">
              <div class="d-flex align-center" style="column-gap: 20px">
                <cz-dropzone
                  accept="image/*"
                  @change="onProfilePictureChange"
                  width="100%"
                  :title="$t('dropzone.title')"
                >
                  <div slot="files" class="py-2 dropzone-files">
                    <cz-chip
                      v-if="selectedProfilePicture"
                      :text="selectedProfilePicture.name"
                      dark
                      color="secondary"
                      circle-edges
                      removeable
                      small
                      @remove="onRemoveProfilePicture"
                    />
                  </div>
                </cz-dropzone>
                <cz-avatar
                  v-if="currentUser"
                  :size="100"
                  color="primary"
                  :name="currentUser.fullName"
                  initials-color="white"
                  initials-size="font-weight-semibold text-h6"
                  :src="currentProfilePicture"
                />
              </div>
            </cz-form-field>
          </v-card-text>
          <v-card-actions class="mt-6">
            <cz-button
              class="px-6"
              :title="$t('common.saveChanges')"
              color="primary"
              :disabled="invalid || !formChanged"
              @click="onSubmit"
              :loading="saving"
              large
            />
          </v-card-actions>
        </v-card>
        <v-img :src="editProfile" :max-width="400" />
      </div>
    </validation-observer>
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import { mapFields } from 'vuex-map-fields';
import editProfile from '@/assets/illustrations/edit-profile.svg';
import { uploadFiles, updateUser } from '@/core/api';
import { mapActions } from 'vuex';
import {
  CzInput,
  CzButton,
  CzFormField,
  CzAutocomplete,
  CzDropzone,
  CzChip,
  CzAvatar
} from '@/components';
import {
  fileReaderPromise,
  ReaderType
} from '@/shared/services/fileReader.service';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

export default {
  components: {
    ContentLayout,
    CzInput,
    CzButton,
    CzFormField,
    CzAutocomplete,
    CzDropzone,
    CzChip,
    CzAvatar
  },
  created() {
    this.currentUserCopy = cloneDeep(this.currentUser);
  },
  computed: {
    ...mapFields('auth', ['currentUser', 'accountId']),
    formChanged() {
      return (
        !isEqual(this.currentUser, this.currentUserCopy) ||
        !!this.selectedProfilePicture
      );
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          handler: () => {
            this.$router.go(-1);
          },
          //   to: {
          //     //   name: 'home'
          //   },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('auth.editProfile'),
          disabled: true,
          exact: true
        }
      ];
    },
    currentProfilePicture() {
      if (this.selectedProfilePictureUrl) {
        return this.selectedProfilePictureUrl;
      } else if (this.currentUser.profilePicture) {
        return this.currentUser.profilePicture.url;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      editProfile,
      genderItems: [],
      selectedProfilePicture: null,
      selectedProfilePictureUrl: null,
      currentUserCopy: null,
      saving: false
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    async onProfilePictureChange(files) {
      this.selectedProfilePicture = files[0];
      this.selectedProfilePictureUrl = await fileReaderPromise(
        files[0],
        ReaderType.URL
      );
    },
    onRemoveProfilePicture() {
      this.selectedProfilePicture = this.selectedProfilePictureUrl = null;
    },
    async onSubmit() {
      try {
        this.saving = true;

        if (this.selectedProfilePicture) {
          const [profilePictureFile] = await uploadFiles(
            [this.selectedProfilePicture],
            this.accountId
          );

          this.currentUserCopy.profilePictureFileId = profilePictureFile._id;
        }

        this.currentUser = await updateUser(this.currentUserCopy._id, {
          fullName: this.currentUserCopy.fullName,
          profilePictureFileId: this.currentUserCopy.profilePictureFileId
        });

        this.currentUserCopy = cloneDeep(this.currentUser);
        this.selectedProfilePicture = this.selectedProfilePictureUrl = null;

        this.showSuccessToastMessage(this.$t('auth.profileSaved'));
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
